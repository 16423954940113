import _ from 'lodash'
import { observable, computed, action, makeObservable } from 'mobx'
import { notifyError, validRequired } from 'uikit'
import FactoryProvider from 'providers/factoryProvider'
import { prepareErrorMessage } from 'utils/error'
import { percentageError, separatedPercentageError } from 'utils/validators/percentage'
import BaseModel from 'models/baseModel/baseModel'
import { ServiceStrategySettingsData } from './serviceStrategySettingsData'

const filterProducts = (products, query) => (
  _.filter(products, (product) => product.viewName.toLowerCase().includes(query.toLowerCase()))
)

class BankPieceData extends BaseModel {
  @observable bankID = null
  @observable bank = {}
  @observable formType = 'full'
  @observable hard = false
  @observable percentage = 0

  @observable insuranceStrategy = 'null_strategy'
  @observable insurancePenetrationPercentage = 0
  @observable bankInsurancePercentage = 0
  @observable externalInsurancePercentage = 0

  @observable smsStrategy = 'null_strategy'
  @observable smsPenetrationPercentage = 0
  @observable bankSmsPercentage = 0
  @observable externalSmsPercentage = 0

  @observable creditProducts = []
  @observable installmentProducts = []
  @observable insuranceProducts = []
  @observable serviceStrategySettings = []

  @observable creditProductSearchResult = []
  @observable installmentProductSearchResult = []
  @observable insuranceProductSearchResult = []

  @observable newAdditionalServiceID = null

  @observable creditProductsOriginal = []
  @observable creditProductGroups = []
  @observable installmentProductsOriginal = []
  @observable installmentProductGroups = []
  @observable insuranceProductsOriginal = []
  @observable insuranceProductGroups = []

  creditProductList = []
  insuranceProductList = []

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isMixedInsuranceStrategy() {
    return this.insuranceStrategy === 'mixed_insurance_strategy'
  }

  @computed get isMixedSmsStrategy() {
    return this.smsStrategy === 'mixed_sms_strategy'
  }

  @computed get isValid() {
    return !this.creditProductsError && !this.serviceStrategySettingsError &&
      !this.insurancePenetrationPercentageError && !this.insurancePercentageError &&
      !this.smsPenetrationPercentageError && !this.smsPercentageError &&
      !this.insuranceStrategyError && !this.smsStrategyError
  }

  @computed get newAdditionalServiceIDError() {
    if (!this.newAdditionalServiceID) return null
    if (_.find(this.serviceStrategySettings, (bp) => bp.additionalServiceID === this.newAdditionalServiceID)) {
      return 'Уже существует'
    }
    return null
  }

  @computed get creditProductsError() {
    if (_.isEmpty(this.creditProducts) && _.isEmpty(this.installmentProducts)) return 'Выберите кредитные продукты или рассрочку'
    return null
  }

  @computed get serviceStrategySettingsError() {
    if (_.find(this.serviceStrategySettings, (serviceStrategySetting) => !serviceStrategySetting.isValid)) {
      return 'Невалидные настройки сервисов'
    }
    return null
  }

  @computed get insurancePenetrationPercentageError() {
    return percentageError(this.insurancePenetrationPercentage)
  }

  @computed get insurancePercentageError() {
    if (!this.isMixedInsuranceStrategy) return null
    return separatedPercentageError([this.bankInsurancePercentage, this.externalInsurancePercentage])
  }

  @computed get smsPenetrationPercentageError() {
    return percentageError(this.smsPenetrationPercentage)
  }

  @computed get smsPercentageError() {
    if (!this.isMixedSmsStrategy) return null
    return separatedPercentageError([this.bankSmsPercentage, this.externalSmsPercentage])
  }

  @computed get insuranceStrategyError() {
    if (validRequired(this.insuranceStrategy)) return validRequired(this.insuranceStrategy)
    return null
  }

  @computed get smsStrategyError() {
    if (validRequired(this.smsStrategy)) return validRequired(this.smsStrategy)
    return null
  }
  @computed get availableInsurancePercentage() {
    if (!this.isMixedInsuranceStrategy) return
    return 100 - this.bankInsurancePercentage - this.externalInsurancePercentage
  }

  @computed get availableSmsPercentage() {
    if (!this.isMixedSmsStrategy) return
    return 100 - this.bankSmsPercentage - this.externalSmsPercentage
  }
  @computed get isCreditProductsChanged() {
    return _.xorBy(this.creditProducts, this.creditProductsOriginal, 'id').length > 0
  }
  @computed get isInstallmentProductsChanged() {
    return _.xorBy(this.installmentProducts, this.installmentProductsOriginal, 'id').length > 0
  }
  @computed get isInsuranceProductsChanged() {
    return _.xorBy(this.insuranceProducts, this.insuranceProductsOriginal, 'id').length > 0
  }

  applyBankPiece = action((bankPiece) => {
    const serviceStrategySettings = _.map(bankPiece.serviceStrategySettings, (serviceStrategySetting) => {
      const serviceStrategySettingsData = new ServiceStrategySettingsData()
      serviceStrategySettingsData.applyData({ ...serviceStrategySetting })
      return serviceStrategySettingsData
    })
    this.applyData({ ...bankPiece, serviceStrategySettings })
  })

  setCreditProducts = action((products) => {
    const creditProducts = _.map(products, (product) => _.find(this.creditProductList, (p) => p.id === product.key))
    this.applyData({ creditProducts })
  })

  setInstallmentProducts = action((products) => {
    const installmentProducts = _.map(products, (product) => _.find(this.creditProductList, (p) => p.id === product.key))
    this.applyData({ installmentProducts })
  })

  setCreditProductGroup = action((productIDs) => {
    const products = this.creditProductList.filter(p => productIDs.indexOf(p.id) !== -1)
    const creditProducts = _.map(products, (product) => _.find(this.creditProductList, (p) => p.id === product.id))
    this.applyData({ creditProducts: _.unionBy(this.creditProducts, creditProducts, 'id') })
  })

  setInstallmentProductGroup = action((productIDs) => {
    const products = this.creditProductList.filter(p => productIDs.indexOf(p.id) !== -1)
    const installmentProducts = _.map(products, (product) => _.find(this.creditProductList, (p) => p.id === product.id))
    this.applyData({ installmentProducts: _.unionBy(this.installmentProducts, installmentProducts, 'id') })
  })

  cancelCreditProducts = action(() => {
    const creditProducts = this.creditProductsOriginal
    this.applyData({ creditProducts })
  })

  cancelInstallmentProducts = action(() => {
    const installmentProducts = this.installmentProductsOriginal
    this.applyData({ installmentProducts })
  })

  setInsuranceProducts = action((products) => {
    const insuranceProducts = _.map(products, (product) => _.find(this.insuranceProductList, (p) => p.id === product.key))
    this.applyData({ insuranceProducts })
  })

  setInsuranceProductGroup = action((productIDs) => {
    const products = this.insuranceProductList.filter(p => productIDs.indexOf(p.id) !== -1)
    const insuranceProducts = _.map(products, (product) => _.find(this.insuranceProductList, (p) => p.id === product.id))
    this.applyData({ insuranceProducts: _.unionBy(this.insuranceProducts, insuranceProducts, 'id') })
  })

  cancelInsuranceProducts = action(() => {
    const insuranceProducts = this.insuranceProductsOriginal
    this.applyData({ insuranceProducts })
  })

  addAdditionalService = action(() => {
    const serviceStrategySettingsData = new ServiceStrategySettingsData()
    serviceStrategySettingsData.applyData({ additionalServiceID: this.newAdditionalServiceID })
    const serviceStrategySettings = this.serviceStrategySettings.concat(serviceStrategySettingsData)
    this.applyData({ serviceStrategySettings, newAdditionalServiceID: null })
  })

  removeAdditionalService = action((serviceID) => {
    const serviceStrategySettings = _.reject(this.serviceStrategySettings, ['additionalServiceID', serviceID])
    this.applyData({ serviceStrategySettings })
  })

  searchCreditProducts = action(({ query }) => {
    const products = _.reject(this.creditProductList, 'onlyInstallments')
    const creditProductSearchResult = query !== '' ? filterProducts(products, query) : products
    this.applyData({ creditProductSearchResult })
  })

  searchInstallmentProducts = action(({ query }) => {
    const installmentProductSearchResult = query !== '' ? filterProducts(this.creditProductList, query) : this.creditProductList
    this.applyData({ installmentProductSearchResult })
  })

  searchInsuranceProducts = action(({ query }) => {
    const insuranceProductSearchResult = query !== '' ? filterProducts(this.insuranceProductList, query) : this.insuranceProductList
    this.applyData({ insuranceProductSearchResult })
  })

  getBankProducts = async () => {
    try {
      const products = await FactoryProvider.BankProvider.getProducts(this.bankID)
      const creditProductList = _.unionBy(this.creditProducts, this.installmentProducts, products.creditProducts, 'id')
      const creditProductGroups = products.creditProductGroups
      const installmentProductGroups = products.installmentProductGroups
      const insuranceProductList = _.unionBy(this.insuranceProducts, products.insuranceProducts, 'id')
      const insuranceProductGroups = products.insuranceProductGroups
      this.applyData({
        creditProductList,
        insuranceProductList,
        creditProductGroups,
        installmentProductGroups,
        insuranceProductGroups,
        creditProductSearchResult: _.reject(creditProductList, 'onlyInstallments'),
        installmentProductSearchResult: creditProductList,
        insuranceProductSearchResult: insuranceProductList
      })
    } catch (e) {
      notifyError(`Ошибка получения списка продуктов банка ${this.bank.name}`, prepareErrorMessage(e))
      throw e
    }
  }

  bankPieceFields = () => [
    'bankID',
    'bank',
    'formType',
    'hard',
    'percentage',
    'insuranceStrategy',
    'insurancePenetrationPercentage',
    'bankInsurancePercentage',
    'externalInsurancePercentage',
    'smsStrategy',
    'smsPenetrationPercentage',
    'bankSmsPercentage',
    'externalSmsPercentage',
    'creditProducts',
    'installmentProducts',
    'insuranceProducts'
  ]

  bankPiece = () => {
    const resolve = {}
    resolve.serviceStrategySettings = _.map(this.serviceStrategySettings, (s) => s.serviceStrategySettings())
    _.each(this.bankPieceFields(), field => {
      resolve[field] = this[field]
    })
    return resolve
  }
}

const INSURANCE_STATEGY_OPTIONS = [
  {
    value: 'null_strategy',
    label: 'Не указана'
  },
  {
    value: 'bank_insurance_strategy',
    label: 'Банковская страховка'
  },
  {
    value: 'external_insurance_strategy',
    label: 'Внешняя страховка'
  },
  {
    value: 'mixed_insurance_strategy',
    label: 'Смешанная (банковская + внешняя)'
  }
]

const SMS_STRATEGY_OPTIONS = [
  {
    value: 'null_strategy',
    label: 'Не указана'
  },
  {
    value: 'external_sms_strategy',
    label: 'Внешнее СМС информирование'
  },
  {
    value: 'bank_sms_strategy',
    label: 'Банковское СМС информирование'
  },
  {
    value: 'mixed_sms_strategy',
    label: 'Cмешанное СМС информирование'
  }
]

export { BankPieceData, INSURANCE_STATEGY_OPTIONS, SMS_STRATEGY_OPTIONS }
