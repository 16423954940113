import _ from 'lodash'
import { observable, computed, makeObservable } from 'mobx'
import BaseModel from 'models/baseModel/baseModel'
import FactoryModel from 'models/factoryModel'

class ServiceStrategySettingsData extends BaseModel {
  @observable additionalServiceID = null
  @observable penetrationPercentage = 0
  @observable amountMin = 0
  @observable amountMax = 0

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get name() {
    return FactoryModel.AdditionalServiceListModel.serviceNameByID(this.additionalServiceID)
  }

  @computed get isValid() {
    return !this.amountMaxError
  }

  @computed get amountMaxError() {
    if (this.amountMax < this.amountMin) return 'Должно быть не меньше значения "от"'
    return null
  }

  serviceStrategySettingsFields = () => [
    'additionalServiceID',
    'penetrationPercentage',
    'amountMin',
    'amountMax'
  ]

  serviceStrategySettings = () => {
    const resolve = {}
    _.each(this.serviceStrategySettingsFields(), field => {
      resolve[field] = this[field]
    })
    return resolve
  }
}

export { ServiceStrategySettingsData }
