import _ from 'lodash'

export const percentageError = (percentage) => {
  if (percentage > 100) return 'Не должен превышать 100%'
  return null
}

export const separatedPercentageError = (parts) => {
  if (_.sum(parts) !== 100) return 'Сумма долей должна быть равна 100%'
  return null
}
